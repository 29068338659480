.route__registration {
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    @media screen and (max-width: 1280px) {
        height: auto;
        min-height: 100vh;
         
     }
    &__main {
        width: 100%;
        height: 100%;
        background: linear-gradient(359.86deg, #000000 9.27%, rgba(0, 0, 0, 0) 99.86%);
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 100px 300px 50px 300px;
        @media screen and (max-width: 1700px) {
            padding: 30px 100px ;
            
        }
        @media screen and (max-width: 1280px) {
           flex-direction: column;
           justify-content: center;
           align-items: center;
            
        }
        @media screen and (max-width: 900px) {
            padding:  20px;
             
         }
        &__left {
            max-width: 589px;
            height: 100%;
            @media screen and (max-width: 1280px) {
                height: auto;
                margin-bottom: 50px;
                 
             }

             &__grid {
                margin-top: 20px;
                display: grid;
                grid-template-rows: 200px;
                grid-template-columns: repeat(3, auto);
                column-gap: 20px;

                & > img {
                    width: auto;
                    height: 100%;
                    border: none;
                    outline: none;
                }
             }
            > h2 {
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                color: #00a3ff;
                margin-top: 100px;
                text-transform: uppercase;
                @media screen and (max-width: 1280px) {
                    margin-top: 30px;
                    text-align: center;
                     
                 }
            }
            > h1 {
                font-size: 54px;
                font-weight: 700;
                line-height: 81px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
                @media screen and (max-width: 1280px) {
                    text-align: center;
                     
                 }
                 @media screen and (max-width: 700px) {
                    font-size: 32px;
                     
                 }
            }
            > p {
                font-size: 18px;
                font-weight: 400;
                line-height: 29px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
                @media screen and (max-width: 1280px) {
                    text-align: center;
                     
                 }
                 @media screen and (max-width: 1280px) {
                   font-size: 16px;
                   line-height: 24px;
                     
                 }
            }
        }
        &__right {
            width: 472px;
            background-color: #03141e;
            border-radius: 5px;
            padding: 30px;
            max-width: 100%;
            > h1 {
                font-size: 30px;
                font-weight: 600;
                line-height: 45px;
                letter-spacing: 0em;
                text-align: center;
                margin-bottom: 40px;
                color: white;
            }
            > label {
                color: white;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
            }
            >input{
                width: 100%;
                height: 50px;
                border: 1px solid white;
                border-radius: 5px;
                margin-bottom: 10px;
                box-sizing: border-box;
                background-color: transparent;
                color: white;
                padding: 0 10px;
                margin-top: 10px;
            }
            >button{
                width: 100%;
                height: 50px;
                background-color: #00A3FF;
                color: white;
                border: none;
                border-radius: 5px;
                margin-top: 30px;
                cursor: pointer;

            }
            > p {
                font-size: 18px;
                font-weight: 400;
                line-height: 29px;
                letter-spacing: 0em;
                text-align: left;
                color: white;
                @media screen and (max-width: 1280px) {
                    text-align: center;
                     
                 }
                 @media screen and (max-width: 1280px) {
                   font-size: 16px;
                   line-height: 24px;
                     
                 }
            }
        }
    }
}
